import React, { useEffect, useRef } from 'react';
import { useState } from 'react';
import useCommanForm from '../../../hooks/form/useCommanForm';
import Button from '../../atoms/button/Button';
import DatePicker from '../DateRangePicker/DatePicker';
import FilterBadgeList from '../../organism/FilterBadgeList/FilterBadgeList';
import Filterbar from '../Filterbar/Filterbar';
import { Controller } from 'react-hook-form';
import { useMediaQuery } from '@mui/material';
import * as yup from 'yup';
import DateTimeRangePicker from '../DateTimeRangePicker/DateTimeRangePicker';
import { CommonFilterFields } from 'constants/filters/commonFilterFields';
import useCustomSearchParams from 'hooks/searchParams/useCustomSearchParams';
import { formToParams } from 'utils/helper/filterBarHelpers';

const schema = yup.object();

const FilterBarWrapper = ({
  FilterForm,
  HeaderForm,
  filterFormFields,
  showDateInputs = true,
  urlFilters = false,
  filterFormKv,
  resetParams = {},
  onSubmit = () => {},
}) => {
  const defaultValues = {
    ...(HeaderForm?.defaultValues ?? {}),
  };

  const headerInputsRef = useRef();

  const {
    handleSubmit,
    register,
    reset,
    control,
    getValues,
    resetField,
    setValue,
    watch,
  } = useCommanForm(schema, defaultValues);

  const { updateSearchParams, deleteSearchParams } = useCustomSearchParams();

  const isTablet = useMediaQuery('(max-width: 1280px)');

  const [appliedFilters, setAppliedFilters] = useState({
    [CommonFilterFields.START_DATE.key]:
      defaultValues[CommonFilterFields.START_DATE.key],

    [CommonFilterFields.END_DATE.key]:
      defaultValues[CommonFilterFields.END_DATE.key],
  });

  useEffect(() => {
    const setFiltersFromParams = async () => {
      if (filterFormKv) {
        filterFormKv.forEach(([k, v]) => setValue(k, v));
        setAppliedFilters(Object.fromEntries(filterFormKv));
      }
    };

    if (urlFilters) setFiltersFromParams();
  }, [filterFormKv, setValue, urlFilters]);

  const handleFormSubmit = (data) => {
    onSubmit?.(data);
    setAppliedFilters(getValues());

    if (urlFilters) {
      updateSearchParams({
        ...formToParams(data, filterFormFields),
        ...resetParams,
      });
    }
  };

  const getResetParams = (key) => {
    return {
      ...resetParams,
      ...(headerInputsRef.current?.getResetParams?.(key) ?? {}),
    };
  };

  const handleResetIndividualField = (key) => {
    resetField(key);
    setValue(key, defaultValues[key] ?? null);

    if (urlFilters) {
      deleteSearchParams([key]);
      updateSearchParams(getResetParams(key));
    } else {
      handleSubmit(handleFormSubmit)();
    }
  };

  const handleCancel = () => {
    reset({
      [CommonFilterFields.START_DATE.key]: null,
      [CommonFilterFields.END_DATE.key]: null,
    });

    if (urlFilters) {
      deleteSearchParams(Object.values(filterFormFields).map((f) => f.key));
      updateSearchParams(resetParams);
    } else {
      handleSubmit(handleFormSubmit)();
    }
  };

  const hanndleOnChange = (event, key) => {
    headerInputsRef.current?.handleOnChange?.(event, key);
  };

  return (
    <>
      <Filterbar
        onSubmit={handleSubmit(handleFormSubmit)}
        onCancel={handleCancel}
        onlyModal
      >
        <FilterForm
          register={register}
          control={control}
        />
      </Filterbar>

      {showDateInputs && (
        <>
          <div className='flex w-full flex-col gap-3 lg:w-max lg:flex-row [&>*]:w-full lg:[&>*]:w-auto [&>div>div>button]:w-full'>
            {HeaderForm && (
              <HeaderForm
                ref={headerInputsRef}
                register={register}
                control={control}
                watch={watch}
                setValue={setValue}
                submit={handleSubmit(handleFormSubmit)}
              />
            )}

            {isTablet ? (
              <>
                <Controller
                  name={CommonFilterFields.START_DATE.key}
                  control={control}
                  render={({ field }) => (
                    <DatePicker
                      field={field}
                      startEndOfDay={'start'}
                      startIcon
                      onChange={(e) =>
                        hanndleOnChange(e, CommonFilterFields.START_DATE.key)
                      }
                      onSubmit={handleSubmit(handleFormSubmit)}
                      onCancel={() =>
                        handleResetIndividualField(
                          CommonFilterFields.START_DATE.key
                        )
                      }
                      showTime
                    />
                  )}
                />

                <Controller
                  name={CommonFilterFields.END_DATE.key}
                  control={control}
                  render={({ field }) => (
                    <DatePicker
                      field={field}
                      startEndOfDay={'end'}
                      endIcon
                      onChange={(e) =>
                        hanndleOnChange(e, CommonFilterFields.END_DATE.key)
                      }
                      onSubmit={handleSubmit(handleFormSubmit)}
                      onCancel={() =>
                        handleResetIndividualField(
                          CommonFilterFields.END_DATE.key
                        )
                      }
                      showTime
                    />
                  )}
                />
              </>
            ) : (
              <DateTimeRangePicker
                control={control}
                watch={watch}
                setToCustomRange={(e) => {
                  hanndleOnChange(e, CommonFilterFields.START_DATE.key);
                  hanndleOnChange(e, CommonFilterFields.END_DATE.key);
                }}
              />
            )}
          </div>

          <Button
            title='Apply'
            type='submit'
            className='hidden lg:flex'
            onClickHandler={handleSubmit(handleFormSubmit)}
          />
        </>
      )}

      <FilterBadgeList
        filters={appliedFilters}
        onClear={handleResetIndividualField}
        onClearAll={handleCancel}
        fields={filterFormFields}
      />
    </>
  );
};

export default FilterBarWrapper;
