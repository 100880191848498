import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { handleAPI } from '../../../utils/api/api';

const initialState = {
  settings: [],
  setting: {},
  totalPages: null,
  totalCount: null,
  pixel: {},
  isLoading: true,
  error: null,
};

export const getPageSettings = createAsyncThunk(
  'pageSettings/getSettings',
  async ({ params }) => {
    const response = await handleAPI('/platform/pixel_pages', 'get', params);
    return response;
  }
);

export const getPageSetting = createAsyncThunk(
  'pageSettings/getSetting',
  async ({ id, params }) => {
    const response = await handleAPI(
      `/platform/pixel_pages/${id}`,
      'get',
      params
    );
    return response;
  }
);

const pageSettingsSlice = createSlice({
  name: 'pageSettings',
  initialState,
  reducers: {
    deletePageSetting: (state, action) => {
      state.settings = state.settings.filter(
        (setting) => setting.id !== action.payload
      );
    },
    updatePageSetting: (state, action) => {
      return {
        ...state,
        setting: { ...state.setting, ...action.payload },
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getPageSettings.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getPageSettings.fulfilled, (state, action) => {
      state.isLoading = false;
      state.settings = action.payload;
      state.totalPages = action?.metaData?.total_pages;
      state.totalCount = action?.metaData?.total_count;
    });
    builder.addCase(getPageSetting.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getPageSetting.fulfilled, (state, action) => {
      state.isLoading = false;
      state.setting = action.payload;
    });
  },
});

export default pageSettingsSlice.reducer;
