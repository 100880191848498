import React, { useEffect } from 'react';
import { RouterProvider } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Toastr from './components/atoms/toasts/Toastr';
import useAxiosPrivate from './hooks/auth/useAxiosPrivate';
import ConfirmationModal from './components/molecule/modal/ConfirmationModal';
import { router } from './constants/routes';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { createTheme, ThemeProvider } from '@mui/material';
import moment from 'moment-timezone';
import PromptModal from 'components/molecule/modal/PromptModal/PromptModal';
import { syncTheme } from './redux/slices/themeSlice';

function App() {
  useAxiosPrivate();
  const { theme } = useSelector((state) => state.theme);
  const { role } = useSelector((state) => state.auth);
  const { abilities } = useSelector((state) => state.auth);
  const { instance_id } = useSelector((state) => state.auth);
  const timezone = useSelector((state) => state.auth.timezone);
  const dispatch = useDispatch();

  useEffect(() => {
    /*
    Sets the momentJS timezone, it's being used by date pickers for output
     */
    if (timezone) {
      moment.tz.setDefault(timezone);
    }
  }, [timezone]);

  useEffect(() => {
    const channel = new BroadcastChannel('reload');

    channel.onmessage = (event) => {
      /* Don't reload the tab that initiated logout/login */
      if (event.data.type === 'auth' && event.data.id !== instance_id) {
        window.location.reload();
      }
    };

    return () => {
      channel.close();
    };
  }, [instance_id]);

  window.addEventListener('storage', (event) => {
    if (event.key === 'theme') {
      dispatch(syncTheme(event.newValue));
    }
  });

  const muiTheme = createTheme({
    typography: {
      fontFamily: ['Inter', 'sans-serif'].join(','),
      fontSize: 14,
    },
  });

  return (
    <ThemeProvider theme={muiTheme}>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <div
          id='body'
          data-theme={theme}
        >
          <RouterProvider
            router={router(role, abilities)}
            fallbackElement={<>loading...</>}
          />
          <Toastr />
          <ConfirmationModal />
          <PromptModal />
        </div>
      </LocalizationProvider>
    </ThemeProvider>
  );
}

export default App;
