import React from 'react';
import PropTypes from 'prop-types';
import Tooltip from '../tooltip/tooltip';

const Checkbox = ({
  color = 'checkbox-primary',
  size = 'checkbox-sm',
  label,
  errors,
  register,
  name,
  infoData,
  className,
  labelClassName = '',
  onClick,
  onChange,
  onBlur,
  ...props
}) => {
  const error = errors?.[name];
  return (
    <>
      <label className='flex items-center gap-2'>
        <input
          type='checkbox'
          className={`checkbox ${color} ${size} ${className}`}
          {...register}
          {...props}
          onClick={(e) => onClick?.(e)}
          onChange={(e) => {
            register?.onChange?.(e);
            onChange?.(e);
          }}
          onBlur={(e) => {
            register?.onBlur?.(e);
            onBlur?.(e);
          }}
        />
        {label && (
          <span
            className={`cursor-pointer text-sm text-text ${labelClassName}`}
          >
            {label}
          </span>
        )}
        {infoData && (
          <Tooltip
            infoData={infoData}
            className='ml-2'
          />
        )}
      </label>
      <p className='text-xs text-error'>{error?.message}</p>
    </>
  );
};

Checkbox.propTypes = {
  label: PropTypes.string,
  color: PropTypes.string,
  name: PropTypes.string,
  size: PropTypes.oneOf([
    'checkbox-xs',
    'checkbox-sm',
    'checkbox-md',
    'checkbox-lg',
  ]),
  className: PropTypes.string,
  register: PropTypes.object,
  errors: PropTypes.object,
  onClick: PropTypes.func,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
};

export default Checkbox;
