import React, { Suspense, lazy } from 'react';
import { Navigate } from 'react-router-dom';
import paths from '../paths';
import Loader from '../../components/atoms/loader/Loader';
const MerchantAccounts = lazy(
  () =>
    import('../../components/pages/payment/merchantAccounts/MerchantAccounts')
);
const Corporations = lazy(
  () => import('../../components/pages/payment/corporations/Corporations')
);
const Banks = lazy(() => import('../../components/pages/payment/banks/Banks'));
const Pools = lazy(() => import('../../components/pages/payment/pools/Pools'));
const RebillPools = lazy(
  () => import('../../components/pages/payment/rebillPools/RebillPools')
);
const AddRebillPools = lazy(
  () => import('../../components/pages/payment/rebillPools/AddRebillPools')
);
const AddPool = lazy(
  () => import('../../components/pages/payment/pools/AddPool')
);
const BinRoutings = lazy(
  () => import('../../components/pages/payment/binRouting/BinRouting')
);
const CardBins = lazy(
  () => import('../../components/pages/payment/binRouting/CardBins')
);
const AddBinRouting = lazy(
  () => import('../../components/pages/payment/binRouting/AddBinRouting')
);
const MerchantProfiles = lazy(
  () =>
    import('../../components/pages/payment/gatewayProfiles/MerchantProfiles')
);
const AddMerchantProfile = lazy(
  () =>
    import('../../components/pages/payment/gatewayProfiles/AddMerchantProfile')
);
const Retries = lazy(
  () => import('../../components/pages/payment/settings/Retries')
);
const ReprocFlowMapping = lazy(
  () => import('../../components/pages/payment/settings/ReprocFlowMapping')
);
const RebillFlowMapping = lazy(
  () => import('../../components/pages/payment/settings/RebillFlowMapping')
);

const createLazyComponent = (Component) => (
  <Suspense fallback={<Loader spinner />}>
    <Component />
  </Suspense>
);

const createRoute = (path, Component) => ({
  path,
  element: createLazyComponent(Component),
});

export const paymentRoutes = (abilities) => {
  const routes = [
    (abilities?.gateway?.read ?? false) &&
      createRoute(paths.merchantAccounts, MerchantAccounts),
    (abilities?.corporation?.read ?? false) &&
      createRoute(paths.corporations, Corporations),
    (abilities?.bank?.read ?? false) && createRoute(paths.banks, Banks),
    (abilities?.pool?.read ?? false) && createRoute(paths.pools, Pools),
    (abilities?.pool?.write ?? false) && createRoute(paths.newPool, AddPool),
    (abilities?.pool?.write ?? false) && createRoute(paths.editPool(), AddPool),
    (abilities?.pool?.read ?? false) &&
      createRoute(paths.rebillPools, RebillPools),
    (abilities?.pool?.write ?? false) &&
      createRoute(paths.editRebillPool(), AddRebillPools),
    (abilities?.pool?.write ?? false) &&
      createRoute(paths.newRebillPool, AddRebillPools),
    (abilities?.bin_routing?.read ?? false) &&
      createRoute(paths.binRoutings, BinRoutings),
    (abilities?.bin_routing?.read ?? false) &&
      createRoute(paths.cardBins, CardBins),
    (abilities?.bin_routing?.write ?? false) &&
      createRoute(paths.newBinRoutings, AddBinRouting),
    (abilities?.bin_routing?.read ?? false) &&
      createRoute(paths.editBinRoutings(), AddBinRouting),
    (abilities?.gateway_profile?.read ?? false) &&
      createRoute(paths.merchantProfiles, MerchantProfiles),
    (abilities?.gateway_profile?.write ?? false) &&
      createRoute(paths.newMerchantProfile, AddMerchantProfile),
    (abilities?.gateway_profile?.write ?? false) &&
      createRoute(paths.editMerchantProfile(), AddMerchantProfile),
    (abilities?.settings?.read ?? false) && createRoute(paths.retries, Retries),
    (abilities?.settings?.read ?? false) &&
      createRoute(paths.reprocflowMapping, ReprocFlowMapping),
    (abilities?.settings?.read ?? false) &&
      createRoute(paths.rebillflowMapping, RebillFlowMapping),
  ].filter(Boolean);

  const firstPermittedPath = routes[0]?.path || '/*';

  return {
    path: paths.getPaymentLayout,
    children: [
      {
        index: true,
        element: (
          <Navigate
            replace={true}
            to={firstPermittedPath}
          />
        ),
      },
      ...routes,
      {
        path: paths.settings,
        element: <Navigate to={paths.retries} />,
      },
    ],
  };
};
