import moment from 'moment';
import { handleAPI } from 'utils/api/api';
import { deserialize } from 'deserialize-json-api';
import { FilterFieldType } from 'constants/filters/filterFieldType';
import { isArray, parseBool } from 'utils/helper/helper';

const ISO_DATE_FORMAT = 'YYYY-MM-DDTHH:mm';

const fieldToParam = (key, value, fields) => {
  const field = Object.values(fields).find((f) => f.key === key);

  if (!key || !value || !field) return null;

  switch (field.type) {
    case FilterFieldType.TEXT:
    case FilterFieldType.TOGGLE:
      return value;

    case FilterFieldType.DROPDOWN:
      return isArray(value) ? value.map((v) => v.id).join(',') : value.id;

    case FilterFieldType.DATE:
      return value.format(ISO_DATE_FORMAT);

    default:
      return null;
  }
};

const fetchSelectedValue = async (endpoint, id) => {
  const response = await handleAPI(endpoint, 'GET', { 'filter[id_eq]': id });
  return deserialize(response.data).data[0];
};

const findSelectedOption = async (key, value, fields) => {
  const array = value.split(',');
  const field = Object.values(fields).find((f) => f.key === key);

  if (!field) return [];

  const selectedOptions = await Promise.all(
    array.map(async (id) =>
      field.endpoint
        ? field.fetchSelectedValue
          ? await field.fetchSelectedValue(id)
          : await fetchSelectedValue(field.endpoint, id)
        : Object.values(field.options).find((o) => String(o.id) === id)
    )
  );

  const validOptions = selectedOptions.filter((o) => !!o);

  return field.isMulti ? validOptions : validOptions[0];
};

const paramToField = async (key, value, fields) => {
  const field = Object.values(fields).find((f) => f.key === key);

  if (!key || !value || !field) return null;

  switch (field.type) {
    case FilterFieldType.TEXT:
      return value;

    case FilterFieldType.TOGGLE:
      return parseBool(value);

    case FilterFieldType.DROPDOWN:
      return await findSelectedOption(key, value, fields);

    case FilterFieldType.DATE:
      return moment(value);

    default:
      return null;
  }
};

export const paramsToForm = async (params, fields) => {
  const entries = await Promise.all(
    Array.from(params).map(async ([key, value]) => [
      key,
      await paramToField(key, value, fields),
    ])
  );

  return entries.filter(([, v]) => !!v);
};

export const formToParams = (form, fields) => {
  return Object.fromEntries(
    Object.entries(form).map(([key, value]) => [
      key,
      fieldToParam(key, value, fields),
    ])
  );
};
