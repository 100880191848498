import React from 'react';
import Loader from '../../atoms/loader/Loader';
import Pagination from '../../organism/pagination/Pagination';
import PropTypes from 'prop-types';
import { PaginationOptions } from 'constants/options/paginationOptions';
import ArrowDropUpDown from '../../icons/ArrowDropUpDown';
import Checkbox from '../../atoms/checkbox/Checkbox';
import Tooltip from '../../atoms/tooltip/tooltip';
import HelpIcon from '../../icons/HelpIcon';

export const SortOrder = {
  NONE: 'none',
  ASC: 'asc',
  DESC: 'desc',
};

const NextSortOrder = {
  [SortOrder.NONE]: SortOrder.ASC,
  [SortOrder.ASC]: SortOrder.DESC,
  [SortOrder.DESC]: SortOrder.NONE,
};

const SortArrow = ({ arrow, active = false }) => {
  return (
    <div className={`ml-1 opacity-40 ${active ? '!opacity-100' : ''}`}>
      <ArrowDropUpDown
        className={'h-[9px] w-[9px]'}
        direction={arrow}
      />
    </div>
  );
};

const Column = ({
  name = '',
  sortOrder = SortOrder.NONE,
  sortable = false,
  onSortChange = () => {},
  isAllSelected,
  toggleAllSelected,
  tooltipText,
}) => {
  const sort = () => {
    const nextOrder = NextSortOrder[sortOrder];
    onSortChange(nextOrder);
  };

  return (
    <div
      className={`flex select-none items-center gap-1 ${sortable ? 'cursor-pointer' : ''}`}
      onClick={sortable ? sort : undefined}
    >
      {toggleAllSelected && (
        <Checkbox
          checked={isAllSelected}
          onChange={toggleAllSelected}
          size='checkbox-xs'
        />
      )}

      <p>{name}</p>

      {sortable && (
        <div className='grid'>
          <SortArrow
            arrow='up'
            active={sortOrder === SortOrder.ASC}
          />

          <SortArrow
            arrow='down'
            active={sortOrder === SortOrder.DESC}
          />
        </div>
      )}
      {tooltipText && (
        <Tooltip
          infoData={{
            tooltip: <HelpIcon svgClassname={'h-4 w-4'} />,
            content: tooltipText,
            position: 'tooltip-top',
          }}
        />
      )}
    </div>
  );
};

const RenderColumns = ({ columns, isSubTable, sort, setSort }) => {
  const renderColumns = columns.map((column) => ({
    name: typeof column === 'object' ? column.name : column,
    sortable: column.sortable ?? false,
    sortKey: column.sortKey ?? undefined,
    activeSort: column.sortKey === sort?.column,
    isAllSelected: column.isAllSelected,
    toggleAllSelected: column.toggleAllSelected,
    tooltipText: column.tooltipText,
  }));

  return (
    <>
      {renderColumns.map((column, index) => (
        <th
          className={`px-2 py-4 text-start first:px-3 last:px-3 ${column.name === 'Actions' && 'border-l border-gray-line'} ${isSubTable && '!py-2'} `}
          key={index}
        >
          <Column
            name={column.name}
            sortable={column.sortable}
            sortOrder={column.activeSort ? sort?.order : SortOrder.NONE}
            onSortChange={(order) =>
              setSort?.({ column: column.sortKey, order })
            }
            isAllSelected={column.isAllSelected}
            toggleAllSelected={column.toggleAllSelected}
            tooltipText={column.tooltipText}
          />
        </th>
      ))}
    </>
  );
};

const RenderTable = ({
  isLoading,
  authError = false,
  dataCount,
  children,
  rowCount = 10,
  columns = [],
  fullScreenAt = 'mobile',
  totalCount,
  totalPages,
  pagination,
  setPagination,
  isSubTable,
  className,
  compactPlaceholder = false,
  sort,
  setSort,
  paginationOptions = PaginationOptions.BASIC,
}) => {
  const hasRows = !isLoading && dataCount > 0;

  return (
    <>
      {isLoading ? (
        <Loader
          columnCount={columns.length}
          rowCount={rowCount}
        />
      ) : (
        <div
          className={`my-6 flex w-[100vw] -translate-x-[var(--mobile-content-x-padding)] flex-col overflow-hidden rounded-none border-y border-gray-line ${!hasRows && 'flex-grow'} ${!pagination && '!border-b-0'} ${
            fullScreenAt === 'mobile'
              ? 'md:w-full md:translate-x-0 md:rounded-lg md:border'
              : 'lg:w-full lg:translate-x-0 lg:rounded-lg lg:border'
          } ${isSubTable && '!my-0 w-full !translate-x-0 !rounded-none !border-0'} ${className} `}
        >
          <div className='overflow-x-auto overflow-y-hidden'>
            <table className='w-full bg-gray-3'>
              <thead
                className={`border-b border-gray-line bg-background-header text-xs font-semibold text-gray-1 ${
                  !hasRows && 'hidden lg:table-header-group'
                }`}
              >
                <tr>
                  <RenderColumns
                    columns={columns}
                    isSubTable={isSubTable}
                    sort={sort}
                    setSort={setSort}
                  />
                </tr>
              </thead>

              {hasRows && <tbody>{children}</tbody>}
            </table>
          </div>

          {!isLoading && !hasRows && !authError && (
            <div
              className={`flex w-full flex-grow flex-col justify-center bg-gray-3 text-center text-sm text-gray-1 ${compactPlaceholder ? 'py-12' : 'py-36 lg:py-80'}`}
            >
              No data found
            </div>
          )}

          {authError && (
            <div
              className={`flex w-full flex-grow flex-col justify-center bg-gray-3 text-center text-sm text-gray-1 ${compactPlaceholder ? 'py-12' : 'py-36 lg:py-80'}`}
            >
              Permissions required. Contact the administrator.
            </div>
          )}

          {!!pagination && hasRows && !authError && (
            <Pagination
              totalCount={totalCount}
              totalPages={totalPages}
              pagination={pagination}
              setPagination={setPagination}
              paginationOptions={paginationOptions}
            />
          )}
        </div>
      )}
    </>
  );
};

RenderTable.propTypes = {
  isLoading: PropTypes.bool,
  dataCount: PropTypes.number,
  children: PropTypes.node,
  rowCount: PropTypes.number,
  columns: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.shape({
        name: PropTypes.string,
        sortKey: PropTypes.string,
        sortabe: PropTypes.bool,
      }),
    ])
  ),
  fullScreenAt: PropTypes.oneOf(['mobile', 'tablet']),
  totalCount: PropTypes.number,
  totalPages: PropTypes.number,
  pagination: PropTypes.object,
  setPagination: PropTypes.func,
  paginationOptions: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.shape({
        key: PropTypes.string,
        value: PropTypes.number,
      }),
    ])
  ),
};

export default RenderTable;
