import { createSlice } from '@reduxjs/toolkit';
import { getCookie, removeCookie } from '../../utils/helper/helper';

const getTheme = () => {
  const cookieTheme = getCookie('theme');
  if (cookieTheme) {
    localStorage.setItem('theme', cookieTheme);
    removeCookie('theme');
    return cookieTheme;
  }
  return localStorage.getItem('theme') ?? 'light';
};

const initialState = {
  theme: getTheme(),
};

const themeSlice = createSlice({
  name: 'theme',
  initialState,
  reducers: {
    changeTheme: (state, action) => {
      localStorage.setItem('theme', action.payload);

      return {
        ...state,
        theme: action.payload,
      };
    },
    syncTheme: (state, action) => {
      return {
        ...state,
        theme: action.payload,
      };
    },
  },
});
export const { changeTheme, syncTheme } = themeSlice.actions;

export default themeSlice.reducer;
