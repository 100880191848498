import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { handleAPI } from '../../../utils/api/api';

const initialState = {
  customers: [],
  customer: {},
  emailLogs: [],
  totalPages: null,
  totalCount: null,
  totalPagesEmailLog: null,
  totalCountEmailLog: null,
  isLoading: true,
  error: null,
};

export const getCustomers = createAsyncThunk(
  'customers/getCustomers',
  (params) => {
    return handleAPI('platform/customers', 'get', params);
  }
);

export const getCustomer = createAsyncThunk(
  'customers/getCustomer',
  ({ id, params }) => {
    return handleAPI(`platform/customers/${id}`, 'get', params);
  }
);

export const getCustomerEmailLogs = createAsyncThunk(
  'customers/getCustomerEmailLogs',
  ({ id, params }) => {
    return handleAPI(`platform/customers/${id}/email_logs`, 'get', params);
  }
);

const customerSlice = createSlice({
  name: 'customer',
  initialState,
  reducers: {
    deleteCustomer: (state, action) => {
      state.customers = state.customers.filter(
        (customer) => customer.id !== action.payload
      );
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getCustomers.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getCustomers.fulfilled, (state, action) => {
      state.isLoading = false;
      state.customers = action.payload;
      state.totalPages = action?.metaData?.total_pages;
      state.totalCount = action?.metaData?.total_count;
    });
    builder.addCase(getCustomers.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    });
    builder.addCase(getCustomer.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getCustomer.fulfilled, (state, action) => {
      state.isLoading = false;
      state.customer = action.payload;
    });
    builder.addCase(getCustomer.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    });
    builder.addCase(getCustomerEmailLogs.pending, (state) => {
      state.emailLogs = undefined;
    });
    builder.addCase(getCustomerEmailLogs.fulfilled, (state, action) => {
      state.emailLogs = action.payload;
      state.totalPagesEmailLog = action?.metaData?.total_pages;
      state.totalCountEmailLog = action?.metaData?.total_count;
    });
    builder.addCase(getCustomerEmailLogs.rejected, (state) => {
      state.emailLogs = undefined;
    });
  },
});

export const { deleteCustomer } = customerSlice.actions;
export default customerSlice.reducer;
