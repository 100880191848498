import { DateRangeOptions } from 'constants/options/dateRangeOptions';
import { FilterFieldType } from './filterFieldType';

export const CommonFilterFields = {
  START_DATE: {
    key: 'startDate',
    type: FilterFieldType.DATE,
  },

  END_DATE: {
    key: 'endDate',
    type: FilterFieldType.DATE,
  },

  DATE_RANGE: {
    key: 'dateRange',
    type: FilterFieldType.DROPDOWN,
    options: DateRangeOptions,
    hideBadge: true,
    isMulti: false,
  },
};
