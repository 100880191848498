import { useSelector } from 'react-redux';
import { useMemo } from 'react';
import { checkNestedValue } from '../../utils/helper/helper';

const useHasAbility = (abilitiesKey) => {
  const abilities = useSelector((state) => state.auth.abilities);
  return useMemo(() => {
    return !abilitiesKey ? true : checkNestedValue(abilities, abilitiesKey);
  }, [abilities, abilitiesKey]);
};

export default useHasAbility;
