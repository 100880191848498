import React from 'react';

const EditActionIcon = ({ className, width = 20, height = 19 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 20 19'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        className={className}
        d='M9.16669 2.8332H3.33335C2.89133 2.8332 2.4674 3.00879 2.15484 3.32135C1.84228 3.63391 1.66669 4.05784 1.66669 4.49986V16.1665C1.66669 16.6086 1.84228 17.0325 2.15484 17.345C2.4674 17.6576 2.89133 17.8332 3.33335 17.8332H15C15.442 17.8332 15.866 17.6576 16.1785 17.345C16.4911 17.0325 16.6667 16.6086 16.6667 16.1665V10.3332M15.4167 1.5832C15.7482 1.25168 16.1978 1.06543 16.6667 1.06543C17.1355 1.06543 17.5852 1.25168 17.9167 1.5832C18.2482 1.91472 18.4345 2.36436 18.4345 2.8332C18.4345 3.30204 18.2482 3.75168 17.9167 4.0832L10 11.9999L6.66669 12.8332L7.50002 9.49986L15.4167 1.5832Z'
        stroke='#5C89D4'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

export default EditActionIcon;
