import React from 'react';

const HelpIcon = ({ svgClassname, pathClassname }) => {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      className={svgClassname}
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M11 18h2v-2h-2zm1-16C6.48 2 2 6.48 2 12s4.48 10 10 10
           10-4.48 10-10S17.52 2 12 2
           m0 18c-4.41 0-8-3.59-8-8
           s3.59-8 8-8 8 3.59 8 8
           -3.59 8-8 8
           m0-14c-2.21 0-4 1.79-4 4
           h2c0-1.1.9-2 2-2s2 .9 2 2
           c0 2-3 1.75-3 5h2
           c0-2.25 3-2.5 3-5
           0-2.21-1.79-4-4-4'
        className={pathClassname}
        fill='currentColor'
      />
    </svg>
  );
};

export default HelpIcon;
