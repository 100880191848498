import React, { useCallback, useEffect, useRef } from 'react';
import CloseButton from '../../../atoms/CloseButton/CloseButton';
import ModalTitle from '../../../atoms/modalTitle/ModalTitle';
import Button from '../../../atoms/button/Button';

const FilterModal = ({
  id = 'filter-modal',
  children,
  className,
  onSubmit,
  onCancel,
  onClose,
  submitButtonDisabled,
  submitButtonTitle = 'Apply filters',
  cancelButtonTitle = 'Clear all',
  openOnLoad,
  title = 'Filters',
}) => {
  const ref = useRef(null);
  const backdropRef = useRef(null);

  useEffect(() => {
    if (openOnLoad) {
      ref.current.showModal();
    }
  }, [openOnLoad]);

  const handleCancel = () => {
    onCancel?.();
    handleClose();
  };

  const handleClose = useCallback(() => {
    onClose?.();
    ref.current?.close();
  }, [onClose]);

  const handleSubmit = useCallback(() => {
    onSubmit?.();
    handleClose();
  }, [onSubmit, handleClose]);

  useEffect(() => {
    const handler = (e) => {
      if (e.key === 'Escape') {
        handleClose();
      }
      if (e.key === 'Enter') {
        e.preventDefault();
        handleSubmit();
      }
    };

    document.addEventListener('keydown', handler);
    return () => {
      document.removeEventListener('keydown', handler);
    };
  }, [handleClose, handleSubmit]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (backdropRef.current?.contains(event.target)) {
        handleClose();
      }
    };

    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [handleClose]);

  return (
    <dialog
      id={id}
      ref={ref}
      className='modal modal-top z-50 mx-auto flex h-screen w-screen items-center justify-end'
    >
      <div
        ref={backdropRef}
        className='lg:h-full lg:flex-grow'
      ></div>

      <div
        className={`${className} relative flex h-screen max-w-full flex-grow flex-col overflow-hidden bg-background lg:max-w-[23.625rem]`}
      >
        <div className='flex items-center justify-between border-b border-gray-2 p-4 lg:border-none lg:px-6 lg:py-0 lg:pt-12'>
          <ModalTitle title={title} />
          <CloseButton
            onClick={handleClose}
            className='right-7 top-7 lg:absolute'
          />
        </div>
        <div className='flex min-h-[10rem] flex-grow flex-col'>
          <div className='grid flex-grow grid-cols-1 place-content-start gap-4 overflow-y-auto overflow-x-hidden px-4 py-6 md:grid-cols-2 lg:grid-cols-1 lg:px-6 lg:py-4'>
            {children}
          </div>

          <div
            className={`z-50 flex w-full gap-4 bg-background px-4 py-6 shadow-modal-button-group lg:flex-col-reverse lg:p-6 [&>*]:flex-grow lg:[&>*]:w-full`}
          >
            <Button
              title={cancelButtonTitle}
              variant='ghost'
              color='neutral'
              onClickHandler={handleCancel}
            />

            <Button
              title={submitButtonTitle}
              disabled={submitButtonDisabled}
              onClickHandler={handleSubmit}
            />
          </div>
        </div>
      </div>
    </dialog>
  );
};

export default FilterModal;
