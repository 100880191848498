import React, { Suspense, lazy } from 'react';
import paths from '../paths';
import Loader from '../../components/atoms/loader/Loader';

const Pages = lazy(() => import('../../components/pages/campaign/pages/Pages'));
const AddPage = lazy(
  () => import('../../components/pages/campaign/pages/AddPage')
);

const createLazyComponent = (Component) => (
  <Suspense fallback={<Loader spinner />}>
    <Component />
  </Suspense>
);

export const campaignPageSettingsRoutes = (abilities) => {
  const createRoute = (path, Component) => ({
    path,
    element: createLazyComponent(Component),
  });

  return {
    path: paths.getCampaignPageSettingsLayout,
    children: [
      createRoute(paths.pages, Pages),
      (abilities?.campaign?.read ?? false) &&
        createRoute(paths.newPage(), AddPage),
      (abilities?.campaign?.read ?? false) &&
        createRoute(paths.editPage(), AddPage),
    ],
  };
};
