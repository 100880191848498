const orderPaths = {
  orders: '/order/orders',
  order: (number = ':number') => `/order/${number}`,
  orderFulfilments: '/order/fulfillments',
  clicks: '/order/clicks',
  subscriptions: '/order/subscriptions',
  subscription: (number = ':number') => `/order/subscriptions/${number}`,
  autoReruns: '/order/auto-reruns',
  transactions: '/order/transactions',
  testOrders: '/order/test-orders',
};

export default orderPaths;
