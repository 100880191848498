import Cookies from 'js-cookie';
import AWS from 'aws-sdk';
import Jsona from 'jsona';
import moment from 'moment-timezone';
import fileDownload from 'js-file-download';
import { OUTLET_WRAPPER_ID } from 'constants/ids';
import { Navigate, useParams } from 'react-router-dom';
import React, { Suspense } from 'react';
import Loader from '../../components/atoms/loader/Loader';
import { ISO_DATE_TIME_REGEX } from 'constants/regex';
import { BASE_DOMAIN } from '../../env';

const dataFormatter = new Jsona();

/**
 * Sets an authentication cookie.
 *
 * @param {string} name
 * @param {string} value
 * @param {boolean} [rememberMe=false] - If true, sets a persistent cookie with a 2-month expiration.
 * @param {boolean} [crossDomain=false] - If true, sets the cookie for cross-domain usage.
 * @param {Object} [options={}] - Custom Options
 */
export const setCookie = (
  name,
  value,
  rememberMe = false,
  crossDomain = false,
  options = {}
) => {
  const crossDomainOptions = {
    path: '/',
    secure: true,
    sameSite: 'None',
    domain: `.${BASE_DOMAIN}`, // needs a . on the beginning
  };

  const defaultOptions = crossDomain ? crossDomainOptions : {};

  if (rememberMe) {
    // Persistent cookie with expiration of 2 months
    const expires = new Date();
    const expirationTime = 3600 * 24 * 60;
    expires.setTime(expires.getTime() + expirationTime * 1000);
    defaultOptions.expires = expires;
  }

  const cookieOptions = { ...defaultOptions, ...options };

  Cookies.set(name, value, cookieOptions);
};

export const getCookie = (name) => {
  return Cookies.get(name);
};

export const removeCookie = (name) => {
  Cookies.remove(name);
};

export const deserializeData = (data) => {
  const response = dataFormatter.deserialize(data);
  return response;
};

export const uploadImageToS3 = async () => {
  const accessKeyId = process.env.REACT_APP_EXOSCALE_ACCESS_KEY_ID;
  const secretAccessKey = process.env.REACT_APP_EXOSCALE_SECRET_ACCESS_KEY;
  const enpoint = process.env.REACT_APP_EXOSCALE_ENDPOINT;
  const region = process.env.REACT_APP_EXOSCALE_REGION;

  AWS.config.update({
    accessKeyId: accessKeyId,
    secretAccessKey: secretAccessKey,
    region: region,
    endpoint: new AWS.Endpoint(enpoint),
    s3ForcePathStyle: true,
  });

  const s3 = new AWS.S3();
  return s3;
};

export const timeFormatter = (time, format = 'DD MMM YYYY HH:mm') => {
  if (time) {
    return moment.parseZone(time).format(format);
  } else {
    return '---';
  }
};

export const timeFormatterUtc = (time, format = 'DD MMM YYYY HH:mm') => {
  if (time) {
    return moment.utc(time).format(format);
  } else {
    return '---';
  }
};

export const capitalizeFirstLetter = (str) => {
  return str?.charAt(0).toUpperCase() + str?.slice(1).toLowerCase();
};

export const camelToTitleCase = (str) => {
  return (
    str?.slice(0, 1)?.toUpperCase() +
    str
      ?.slice(1)
      ?.replace(/([A-Z])/g, ' $1')
      .toLowerCase()
  );
};

export const getStatusColor = (status) => {
  switch (status) {
    case 'partial':
    case 'partial_refunded':
    case 'pending':
    case 'soft_decline':
    case 'processing':
    case 'hold':
    case 'rma_pending':
    case 'confirm_pending':
      return 'text-primary';

    case 'complete':
    case 'completed':
    case 'success':
    case 'shipped':
    case 'delivered':
    case 'active':
    case 'refunded':
    case 'true':
      return 'text-green';

    case 'declined':
    case 'failed':
    case 'hard_decline':
    case 'canceled':
    case 'inactive':
    case 'null':
      return 'text-danger';

    default:
      return '';
  }
};

export const formatStatus = (label) => {
  return label?.replace(/_/g, ' ');
};

export const removeEmptyData = (obj) => {
  if (obj && typeof obj === 'object') {
    Object.keys(obj).forEach((key) => {
      if (
        obj[key] &&
        typeof obj[key] === 'object' &&
        !Array.isArray(obj[key])
      ) {
        removeEmptyData(obj[key]);
      } else if (
        obj[key] === '' ||
        obj[key] === null ||
        obj[key] === undefined
      ) {
        delete obj[key];
      }
    });
  }
  return obj;
};

export const fileDownloader = (data, filename = 'report.csv') => {
  fileDownload(data, filename);
};

export const csvExport = (data, page) => {
  const datestamp = moment().format('YYYYMMDD_HHmmss');
  fileDownloader(data, `${page}_${datestamp}.csv`);
};

export const checkNestedValue = (abilities, key) => {
  const levels = key.split('.');
  let current = abilities;

  for (const level of levels) {
    if (!Object.prototype.hasOwnProperty.call(current, level)) {
      return false;
    }
    current = current[level];
  }

  const checkValue = (value) => {
    if (typeof value === 'boolean' && value) {
      return true;
    } else if (typeof value === 'object') {
      return Object.values(value).some((v) => checkValue(v));
    }
    return false;
  };

  return checkValue(current);
};

export const scrollToTop = (id = OUTLET_WRAPPER_ID) => {
  setTimeout(() =>
    document.getElementById(id)?.scrollTo({ top: 0, behavior: 'smooth' })
  );
};

/**
 * This function is a hack to solve the problem of deserialized objects becoming recursive, when using the
 * 'jsona' library in this project.
 * For example the corporation model includes relationship gateways model, which has a corporation relationship...
 * It's a quick hack to get around these edge cases.
 * @param {*} data
 * @param {string} objectKey
 */
export const sanitizeData = (data, objectKey) => {
  const traverseAndSanitize = (obj, depth = 0) => {
    if (typeof obj !== 'object' || obj === null) return obj;

    const sanitizedObj = Array.isArray(obj) ? [] : {};

    for (const key in obj) {
      if (key === objectKey && depth > 0) {
        sanitizedObj[key] = obj[key].map((corporation) => ({
          id: corporation.id,
          name: corporation.name,
        }));
      } else {
        sanitizedObj[key] = traverseAndSanitize(obj[key], depth + 1);
      }
    }

    return sanitizedObj;
  };

  return traverseAndSanitize(data);
};

export const convertToUserTimezone = (
  date,
  timezone = getCookie('timezone')
) => {
  if (!date) return null;

  const browserTimezone = moment.tz.guess();

  const dateInUserTimezone = moment.tz(date, timezone);
  const dateWithoutZone = dateInUserTimezone.format('YYYY-MM-DDTHH:mm:ss.SSS');
  const dateInBrowserTimezone = moment.tz(dateWithoutZone, browserTimezone);

  return dateInBrowserTimezone;
};

export const convertToSelectedTimezone = (
  date,
  timezone = getCookie('timezone')
) => {
  if (!date) return null;

  const browserTimezone = moment.tz.guess();

  const dateInBrowserTimezone = moment.tz(date, browserTimezone);
  const dateWithoutZone = dateInBrowserTimezone.format(
    'YYYY-MM-DDTHH:mm:ss.SSS'
  );
  const dateInDesiredTimezone = moment.tz(dateWithoutZone, timezone);

  return dateInDesiredTimezone;
};

export const transformNumberFieldValue = (value) =>
  isNaN(value) ? null : value;

export const addFilterField = (
  field,
  value,
  additionalCondition = true,
  addFalseBoolean = false
) => {
  return {
    ...(additionalCondition &&
      value !== undefined &&
      value !== null &&
      value !== '' &&
      (addFalseBoolean || value !== false) && { [field]: value }),
  };
};

export const addDateFilterField = (field, value, dayBoundary) => {
  if (!value) {
    return undefined;
  }

  const adjustedValue = value.clone();

  if (dayBoundary === 'start') {
    adjustedValue.startOf('day');
  } else if (dayBoundary === 'end') {
    adjustedValue.endOf('day');
  }

  return addFilterField(field, adjustedValue.toISOString());
};

const ValidatedRoute = ({
  component: Component,
  validate,
  fallback = '/*',
}) => {
  const { number } = useParams();

  if (!validate(number)) {
    return (
      <Navigate
        to={fallback}
        replace={true}
      />
    );
  }

  return <Component />;
};

export const createValidatedRoute = (path, Component, validate, fallback) => ({
  path,
  element: (
    <Suspense fallback={<Loader spinner />}>
      <ValidatedRoute
        component={Component}
        validate={validate}
        fallback={fallback}
      />
    </Suspense>
  ),
});

export const isValidAlphanumericId = (id) => {
  const alphanumericRegex = /^[A-Za-z0-9]{12}$/;
  return alphanumericRegex.test(id);
};

export const getUrlInfo = () => {
  const url = window.location.href;

  return {
    protocol: url.split('//')[0],
    subdomain: url.split('//')[1].split('.')[0],
  };
};

export const generateUniqueEnoughId = () => {
  return `${Date.now()}-${Math.random().toString(36).slice(2, 9)}`;
};

export const setReferenceDate = (startEndOfDay) => {
  if (startEndOfDay === 'start') {
    return moment().startOf('day');
  } else if (startEndOfDay === 'end') {
    return moment().endOf('day');
  }
  return undefined;
};

export const isObject = (v) =>
  v !== null && typeof v === 'object' && !Array.isArray(v);

export const isBoolean = (v) => typeof v === 'boolean';

export const isNumber = (v) => typeof v === 'number' && isFinite(v);

export const isBigInt = (v) => typeof v === 'bigint';

export const isString = (v) => typeof v === 'string';

export const isSymbol = (v) => typeof v === 'symbol';

export const isFunction = (v) => typeof v === 'function';

export const isArray = (v) => Array.isArray(v);

export const isMoment = (v) => moment.isMoment(v);

export const isDate = (v) => v instanceof Date;

export const isIsoDateString = (v) =>
  ISO_DATE_TIME_REGEX.test(v) && moment(v, moment.ISO_8601, true).isValid();

export const parseBool = (v) => v?.toLowerCase() === 'true';

export const getBaseDomainFromUrl = (url) => {
  const parts = url.split('.');

  if (parts.length >= 2) {
    return `${parts[parts.length - 2]}.${parts[parts.length - 1]}`;
  }

  return '';
};

export const toSnakeCase = (input = '') => {
  return input
    .trim()
    .toLowerCase()
    .replace(/[\s-]+/g, '_')
    .replace(/[^a-z0-9_]/g, '')
    .replace(/_+/g, '_');
};

export const linkLineItemToFulfillment = (lineItemId, fulfillments) =>
  fulfillments.reduce((item, { items_json }) => {
    return (
      item || items_json.find(({ line_item_id }) => line_item_id === lineItemId)
    );
  }, null);
