import React from 'react';

const ExclamationIcon = ({ svgClassname, pathClassname = '' }) => {
  return (
    <svg
      width='16'
      height='16'
      viewBox='0 0 16 16'
      fill='none'
      className={svgClassname}
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M8.00004 5.33594V8.0026M8.00004 10.6693H8.00671M5.24004 1.33594H10.76L14.6667 5.2426V10.7626L10.76 14.6693H5.24004L1.33337 10.7626V5.2426L5.24004 1.33594Z'
        stroke='#FF3B30'
        className={`stroke-current ${pathClassname}`}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

export default ExclamationIcon;
