import { useCallback, useEffect, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { paramsToForm } from 'utils/helper/filterBarHelpers';
import { isString } from 'utils/helper/helper';

const isEmpty = (value) => {
  return (
    value === null || value === undefined || (!isString(value) && isNaN(value))
  );
};

const splitFilledAndEmptyParams = (params) =>
  Object.entries(params).reduce(
    (acc, [key, value]) => {
      (isEmpty(value) ? acc.empty : acc.filled).push([key, value]);
      return acc;
    },
    { filled: [], empty: [] }
  );

const useCustomSearchParams = (filterFields) => {
  const filterFieldsRef = useRef(filterFields);
  const [searchParams, setSearchParams] = useSearchParams();
  const paramsObject = Object.fromEntries(searchParams);
  const [isLoadingParams, setIsLoadingParams] = useState(true);
  const [filterFormKv, setFilterFormKv] = useState(undefined);
  const [filterFormValues, setFilterFormValues] = useState(undefined);

  useEffect(() => {
    let isMounted = true;

    const setFiltersFromParams = async () => {
      if (filterFieldsRef.current) {
        setIsLoadingParams(true);

        const formKv = await paramsToForm(
          searchParams,
          filterFieldsRef.current
        );

        if (isMounted) {
          setIsLoadingParams(false);
          setFilterFormKv(formKv);
          setFilterFormValues(Object.fromEntries(formKv));
        }
      }
    };

    setFiltersFromParams();

    return () => {
      isMounted = false;
    };
  }, [searchParams]);

  const deleteSearchParams = useCallback(
    (keys = [], opts = { replace: true }) => {
      setSearchParams((params) => {
        keys.forEach((key) => params.delete(key));
        return params;
      }, opts);
    },
    [setSearchParams]
  );

  const updateSearchParams = useCallback(
    (params, opts = { replace: true, updateEmpty: false }) => {
      if (Object.keys(params).length || opts.updateEmpty) {
        setSearchParams((current) => {
          const splitParams = splitFilledAndEmptyParams(params);

          splitParams.empty.forEach(([key]) => current.delete(key));

          return {
            ...Object.fromEntries(current),
            ...Object.fromEntries(splitParams.filled),
          };
        }, opts);
      }
    },
    [setSearchParams]
  );

  return {
    searchParams,
    paramsObject,
    filterFormKv,
    filterFormValues,
    isLoadingParams,
    setSearchParams,
    updateSearchParams,
    deleteSearchParams,
  };
};

export default useCustomSearchParams;
