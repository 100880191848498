import Tooltip from '../tooltip/tooltip';
import ExclamationIcon from '../../icons/ExclamationIcon';
import React from 'react';

const AuthWarningTooltip = () => (
  <Tooltip
    className={'!z-50'}
    infoData={{
      title: 'Permissions required. Contact the administrator.',
      tooltip: <ExclamationIcon pathClassname={'text-yellow-500'} />,
      position: 'tooltip-top',
    }}
  />
);

export default AuthWarningTooltip;
