import React from 'react';

import CopyActionIcon from '../../icons/actions/CopyActionIcon';
import EditActionIcon from '../../icons/actions/EditActionIcon';
import DeleteActionIcon from '../../icons/actions/DeleteActionIcon';
import SendActionIcon from '../../icons/actions/SendActionIcon';
import DownloadIcon from '../../icons/DownloadIcon';

const TableActionButton = ({ onClick, type = 'edit', className, disabled }) => {
  const types = {
    edit: EditActionIcon,
    copy: CopyActionIcon,
    delete: DeleteActionIcon,
    sendMail: SendActionIcon,
    download: DownloadIcon,
  };

  const Icon = types[type];

  return (
    <button
      onClick={onClick}
      className={`group p-2 ${className}`}
      disabled={disabled}
    >
      <Icon
        className={`stroke-secondary transition-all ${disabled ? '!stroke-gray-300' : 'group-hover:stroke-primary'}`}
      />
    </button>
  );
};

export default TableActionButton;
